<template>
  <div data-vue-component-name="XsysTv">
    <img
      src="backgrounds/Xsys Tv.png"
      alt="background image"
      draggable="false"
    >

    <AppWrapper
      bg-color="rgba(0, 0, 0, 0.2)"
      blur="32"
    >
      <ButtonBack @click="goBack" />
      <h2>{{ pageTitle }}</h2>
      <p>{{ pageDescription }}</p>
      <hr>
      <span>Content:</span>

      <XsysTvList
        v-model="activeItemIndex"
        :items="tvList"
      />
    </AppWrapper>

    <XsysTvSlider
      :links="links"
      v-model="activeItemIndex"
      ref="slider"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { metaController } from '@/modules';
import { AppWrapper, ButtonBack, XsysTvList } from '@xsys2/components';

// meta
metaController({
  title: 'Xsys Tv Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/Xsys Tv.png',
      as: 'image',
    },
  ],
});

const store = useStore();
const router = useRouter();
const slider = ref(null);

store.dispatch('xsysTv/getData');

const goBack = () => router.go(-1);
const pageTitle = computed(() => store.state.xsysTv.pageTitle);
const pageDescription = computed(() => store.state.xsysTv.pageDescription);
const tvList = computed(() => store.state.xsysTv.tvList);

const activeItemIndex = ref(0);
const links = computed(() => tvList.value.map(item => item.video));
</script>

<style scoped lang="scss">
[data-vue-component-name="XsysTv"] {
  position: relative;

  img {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  [data-vue-component-name="AppWrapper"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 20.833vw;
    padding: 1.667vw;

    @include firefox {
      background: rgba(0, 0, 0, 0.64) !important;
    }

    [data-vue-component-name="ButtonBack"] {
      margin-bottom: 1.667vw;
    }

    h2 {
      font-weight: 800;
      font-size: 1.667vw;
      line-height: 2.292vw;
      letter-spacing: 0.1em;
      color: #FFFFFF;
      margin-bottom: 1.250vw;
    }

    p,
    span {
      font-size: 0.938vw;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.75;
      margin-bottom: 1.667vw;
    }

    hr {
      border: none;
      height: 1px;
      width: 100%;
      background-color: #fff;
      opacity: 0.1;
      margin-bottom: 1.667vw;
    }
  }

  [data-vue-component-name="XsysTvSlider"] {
    @include absolute-full;
    z-index: 0;
  }
}
</style>
